<template>
  <div>
    <!-- {{ editValue }} -->
    <!-- {{ value }} -->
    <v-text-field
      :label="label"
      :value="selection"
      append-icon="mdi-menu-down"
      @click:append="menu_click"
      :disabled="disabled"
      @click="menu_click"
    />
    <v-dialog :value="dialogVisible" persistent max-width="600px">
      <GroupsDropdownDialog
        :groups="groups"
        @close="dialog_close"
        :value="editValue"
      />
    </v-dialog>
  </div>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import GroupsDropdownDialog from "@/components/FormComponents/GroupsDropdownDialog";

export default {
  name: "GroupsDropdown",
  components: {
    GroupsDropdownDialog
  },
  props: {
    label: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default() {
        return [];
      }
    },
    value: {
      type: Array,
      default: null
    },
    ownedBy: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      editValue: null,
      groups: null,
      dialogVisible: false,
      loading: false
    };
  },
  computed: {
    selectedGroups() {
      if (this.editValue == null) return [];
      if (this.groups == null) return [];
      return this.groups.filter(p => this.editValue.includes(p._uri));
    },
    selection() {
      if (this.loading) return this.$t("loading");
      return this.selectedGroups.map(p => p.name);
    },
    disabled() {
      return !this.ownedBy;
    }
  },
  watch: {
    value(v) {
      this.setEditValue(v);
    },
    ownedBy: {
      immediate: true,
      handler(v) {
        this.ownedBy_change(v);
      }
    }
  },
  created() {
    this.setEditValue(this.value);
  },
  methods: {
    setEditValue(v) {
      this.editValue = v || [];
    },
    async getGroups(rootToken) {
      if (!rootToken) {
        this.groups = [];
        return;
      }

      try {
        this.loading = true;
        // const url = `/groups?filter=groupToken:ic:${rootToken}page=0&limit=1000&sort=groupToken:asc&fields=name,code,groupToken,_uri`
        // const url = `/groups?filter=groupToken:ic:${rootToken}&page=0&limit=1000&sort=groupToken:asc&fields=name,code,groupToken,_uri`
        const url = `/groups?groupToken=${rootToken}&page=0&limit=1000&sort=groupToken:asc&fields=name,code,groupToken,_uri`;
        const response = await callBffAPI({ url, method: "GET" });
        this.groups = response.data;
      } finally {
        this.loading = false;
      }
    },
    menu_click() {
      if (this.groups == null) return;
      this.dialogVisible = true;
    },
    dialog_close(v) {
      // console.log(v)
      this.editValue = v;
      this.dialogVisible = false;
      this.emit();
    },
    emit() {
      this.$emit("input", this.editValue);
    },
    async ownedBy_change(v) {
      // console.log("ownedBy_change")
      if (!v) {
        this.groups = null;
        return;
      }
      const rootToken = await this.getGroupTokenOwnedByCustomer(v);
      // console.log("rootToken", rootToken)
      this.getGroups(rootToken);
    }
  }
};
</script>
