<template>
  <v-card>
    <!-- header -->
    <v-toolbar color="primary" dark>{{ $t("groups") }}</v-toolbar>

    <!-- search -->
    <v-sheet class="pa-4">
      <v-text-field
        v-model="search"
        :label="$t('search')"
        hide-details
        clearable
        clear-icon="mdi-close"
      />
    </v-sheet>

    <!-- tree -->
    <v-sheet class="px-4" style="height: 500px; overflow: scroll">
      <v-treeview
        dense
        selectable
        :selection-type="selectionType"
        :items="items"
        :search="search"
        :filter="filter"
        :open.sync="open"
        :value="editValue"
        @input="onChange"
      >
        <!-- <template v-slot:prepend="{ item }">
          <v-icon
            v-if="item.children"
            v-text="`mdi-${item.id === 1 ? 'home-variant' : 'folder-network'}`"
          ></v-icon>
        </template> -->
      </v-treeview>
    </v-sheet>

    <!-- close -->
    <v-card-actions>
      <v-spacer />
      <v-btn color="mb-3 close-button px-4" @click="close_click">{{
        $t("close")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "GroupsDropdownDialog",
  props: {
    value: {
      type: Array,
      default: null
    },
    groups: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      editValue: null,
      items: [],
      open: [],
      search: null,
      selectionType: "independent"
    };
  },
  computed: {
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1;
    }
  },
  watch: {
    value(v) {
      this.setEditValue(v);
    },
    groups(v) {
      this.updateItems();
    }
  },
  created() {
    this.setEditValue(this.value);
    this.updateItems();
  },
  methods: {
    setEditValue(v) {
      this.editValue = v || [];
    },
    updateItems() {
      const items = [];
      // console.log(this.groups)

      const groups = this.groups
        .slice()
        .sort((a, b) => a.groupToken.length - b.groupToken.length);
      // console.log(groups)
      for (let group of groups) {
        const item = {
          id: group._uri,
          name: group.name,
          groupToken: group.groupToken,
          children: []
        };
        // const parent = items.find(
        //   p =>
        //     p.groupToken ==
        //     group.groupToken.substring(0, group.groupToken.length - 4)
        // );
        const parent = this.treeFind(
          items,
          p =>
            p.groupToken ==
            group.groupToken.substring(0, group.groupToken.length - 4)
        );
        if (parent) {
          parent.children.push(item);
        } else {
          items.push(item);
        }
      }
      // console.log(items)
      this.items = items;
    },
    onChange(v) {
      this.editValue = v;
    },
    close_click() {
      this.$emit("input", this.editValue);
      this.$emit("close", this.editValue);
    }
  }
};
</script>
